import React from "react";
import styled from "styled-components";
import logo from "../../../assets/logo/svg/white-no-bkg.svg";
import {
  mobileBreakpoint,
  spacing12,
  spacing24,
  spacing32,
} from "../../../theme";
import Button from "../button/Button";
import { TopBarProps as Props } from "../../../types/topBar";

export const AuthenticatedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing24} ${spacing32};
  color: grey;
  font-size: 10px;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    margin: ${spacing12};
  }
`;

export const UnauthenticatedContainer = styled.div`
  color: white;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing24} ${spacing32};
  font-size: 10px;
  top: 0;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    margin: ${spacing12};
  }
`;

export const LogoContainer = styled.div<{
  clickable: boolean; 
}>`
  cursor: pointer;
  cursor: ${({ clickable }) => (clickable ? `pointer` : `default`)};
  color: grey;
  width: auto;
`;

export const SvLogo = styled.img`
  height: 50px;
`;

export const ButtonContainer = styled.div`
  width: 100px;
`;

export const LogOutButton = styled.div`
  height: 40px;
  width: 100%;
  color: grey;
  font-size: 16px;
  font-weight: 700;
  line-height: 38px;
  cursor: pointer;
  border-radius: 10px;
  font-family: Lato;
  border: 2px solid grey;
  text-align: center;
  background-color: rbg(0, 0, 0, 0);
  &:hover {
    color: #3930a4;
  }
`;

const Topbar: React.FC<Props> = ({ isAuthenticated }: Props) => {
  const goHome = () => {
    window.location.href = "/";
  };

  const loginWithRedirect = () => {
    window.location.href = "/login";
  };

  const logout = () => {
    console.log("LOGOUT CLICKED");
  };

  return (
    <>
    { isAuthenticated ? (
      <AuthenticatedContainer>
        <LogoContainer onClick={() => goHome()} clickable={true}>
          <SvLogo src={logo} alt={logo} />
        </LogoContainer>
        <ButtonContainer>
          <LogOutButton onClick={() => logout()}>Log Out</LogOutButton>
        </ButtonContainer>
      </AuthenticatedContainer>
      ) : (
      <UnauthenticatedContainer>
        <ItemsContainer>
          <LogoContainer clickable={false}>
              <SvLogo src={logo} alt={logo} />
            </LogoContainer>
            <ButtonContainer>
              <Button
                text="Log In"
                onClick={() => loginWithRedirect()}
                isActive={false}
              />
          </ButtonContainer>
        </ItemsContainer>

      </UnauthenticatedContainer>
      )}</>
    
    
  );
};

export default Topbar;
