import React from "react";
import styled from "styled-components";
import {
  defaultTextColor,
  highlighted,
  logoBlue,
  spacing8,
} from "../../../theme";
import { ButtonProps as Props } from "../../../types/button";
import Icon from "../icon/Icon";

const ButtonContainer = styled.button<{ isActive: boolean }>`
  background-color: white;
  height: 36px;
  width: 100%;
  color: ${({ isActive }) => (isActive ? highlighted : defaultTextColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing8};
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px solid ${logoBlue};
  text-align: center;
  &:hover {
    color: #3930a4;
  }
  &:disabled {
    color: grey;
    cursor: not-allowed;
    border: 2px solid grey;
  }
`;

const Button: React.FC<Props> = ({
  text,
  onClick,
  isActive,
  icon,
  disabled = false,
}) => {
  return (
    <ButtonContainer isActive={isActive} onClick={onClick} disabled={disabled}>
      {text} {icon && <Icon icon={icon} size="22px" iconTheme="Dark" />}
    </ButtonContainer>
  );
};

export default Button;
