import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "../routes";
import Topbar from "../common/topbar/topbar";
// import PrivateRoute from "../common/privateRoute";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <main>
        <Topbar isAuthenticated={false} />
        <Switch>
          {routes.map((route) => {
            const {
              path,
              authenticatedRoute,
              component: Component,
              componentProps,
              ...otherProps
            } = route;
            // if (authenticatedRoute) {
            //   return (
            //     <PrivateRoute
            //       path={path}
            //       render={(props: any) => <Component {...props} {...componentProps} />}
            //       {...otherProps}
            //       key={`route-${path}`}
            //     />
            //   );
            // }
            return (
              <Route
                exact
                path={path}
                render={(props: any) => (
                  <Component {...props} {...componentProps} />
                )}
                {...otherProps}
                key={`route-${path}`}
              />
            );
          })}
        </Switch>
        {/* <Footer /> */}
      </main>
    </Router>
  );
};

export default AppRouter;
