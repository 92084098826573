// Colors

export const defaultTextColor = "black";
export const goalRed = "#C8102E";
export const assistGrey = "#898D8D";
export const logoBlue = "#314459";
export const highlighted = "#4318FF";
export const menuGrey = "#595959";
export const accentText = "#a3aed0";
export const svBlue = "#E9EFFF";

// Spacing

export const spacing4 = "4px";
export const spacing8 = "8px";
export const spacing12 = "12px";
export const spacing16 = "16px";
export const spacing24 = "24px";
export const spacing32 = "32px";
export const spacing48 = "48px";
export const spacing64 = "64px";
export const spacing86 = "86px";

// Screen Widths

export const maxContainerSize = "1128px";

// Breakpoints

export const mobileBreakpoint = "900px";
