import React from "react";
import styled from "styled-components";
import { highlighted } from "../../../theme";
import { IconProps as Props, IconTheme } from "../../../types/icon";

const iconColor = {
  Light: "white",
  Dark: "black",
  Blue: highlighted,
  Grey: "#353535",
};

const IconImg = styled.span<{ size?: string; iconTheme: IconTheme }>`
    user-select: none;
    cursor: pointer;
    -webkit-user-select: none:
    font-size: ${({ size }) => size};

    &.material-icons-outlined {
        max-width: ${({ size }) => size};
        font-size: ${({ size }) => size};
        color: ${({ iconTheme }) => iconColor[iconTheme]};
    }
`;

const Icon: React.FC<Props> = ({ size, icon, iconTheme, onclick }) => {
  return (
    <>
      <IconImg
        className="material-icons-outlined"
        size={size}
        iconTheme={iconTheme}
        onClick={onclick}
      >
        {icon}
      </IconImg>
    </>
  );
};

export default Icon;
