import styled from "styled-components";
import {
  logoBlue,
  mobileBreakpoint,
  spacing12,
  spacing16,
  spacing24,
  spacing32,
  spacing48,
  spacing8,
  spacing86,
} from "../../theme";
import AndrewDickson from "../../assets/images/AndrewDickson.jpg";
import Platforms from "../../assets/images/platforms.png";
import ManchesterStorm from "../../assets/images/ManchesterStorm.png";
import "react-multi-carousel/lib/styles.css";
import "./home.css";
import { content } from "../../content/content";

const ParentContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  font-family: Raleway;
`;

const BackgroundImage = styled.div`
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 55%,
      rgba(54, 54, 54, 1) 100%
    ),
    url("images/background.jpg") center center/cover no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const PlatformsImg = styled.img`
  max-width: 300px;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    width: 75%;
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${spacing48};
  z-index: 2;
  gap: ${spacing48};
  @media only screen and (max-width: ${mobileBreakpoint}) {
    margin: ${spacing48} ${spacing12};
  }
`;

const BigText = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  text-align: center;
  width: 100%;
  font-family: Raleway;
  font-weight: bold;
  margin-top: ${spacing86};
  text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    text-shadow: 0.02em 0 black, 0 0.02em black, -0.02em 0 black,
      0 -0.02em black;
  }
`;

const Screenshot = styled.img`
  border-radius: 10px;
  width: 90%;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    display: none;
  }
`;

const ReviewHeading = styled.div`
  font-size: 18px;
  color: white;
  margin: ${spacing16} 0;
  font-family: "Montserrat";
  text-align: left;
  font-weight: bold;
  width: 100%;
  grid-column: span 2;
`;

const IconHeading = styled.div`
  font-size: 18px;
  color: white;
  font-family: "Montserrat";
  text-align: center;
  font-weight: bold;
  text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
`;

const BodyText = styled.div`
  font-size: 16px;
  color: white;
  max-width: 60%;
  font-family: Raleway;
  text-align: center;
  text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    max-width: unset;
  }
`;

const CirclesContainer = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  row-gap: ${spacing24};
  width: 100%;
  margin-top: ${spacing32};
  @media only screen and (max-width: ${mobileBreakpoint}) {
    grid-template-columns: 1fr;
  }
`;

const CircleAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing24};
  width: 100%;
`;

const Circle = styled.div`
  height: 250px;
  width: 250px;
  border-radius: 50%;
  background-color: white;
  border: 22px solid ${logoBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    height: 150px;
    width: 150px;
  }
`;

const IconImg = styled.span`
  user-select: none;
  font-size: 170px;
  color: #353535;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    font-size: 110px;
  }
`;

const ReviewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column: span 2;
  row-gap: ${spacing32};
  color: #fff;
  max-width: 75%;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    grid-template-columns: 1fr;
    text-align: center;
    row-gap: ${spacing8};
  }
`;

const TestimonyText = styled.div`
  line-height: 22px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  text-shadow: 0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    align-items: center;
    grid-column: span 2;
  }
`;

const DemoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${spacing48};
  padding: 0 ${spacing32} ${spacing32};
  border: 2px solid white;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  text-align: center;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    width: 80%;
    margin-top: ${spacing12};
  }
`;

const DemoHeading = styled.h2`
  font-size: 32px;
  color: white;
  font-family: "Montserrat";
  margin-bottom: ${spacing24};
`;

const DemoText = styled.p`
  font-size: 18px;
  color: white;
  font-family: Raleway;
  margin-bottom: ${spacing24};
`;

const DemoButton = styled.a`
  text-decoration: none;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 50%;
  height: 60px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
  background-color: white;
  font-family: Montserrat;
  border: 2px solid rgba(0, 0, 0, 0);
  color: black;
  &:hover {
    border: 2px solid black;
  }
  @media only screen and (max-width: ${mobileBreakpoint}) {
    width: 80%;
  }
`;

const Home = () => {
  return (
    <>
      <BackgroundImage />
      <ParentContainer>
        <ContentContainer>
          <BigText>{content.bigText}</BigText>
          <BodyText>{content.bodyText}</BodyText>
          <CirclesContainer>
            <CircleAndText>
              <Circle>
                <IconImg className="material-icons-outlined">
                  {"videocam"}
                </IconImg>
              </Circle>
              <IconHeading>{content.secureCloudVideoStorage.heading}</IconHeading>
              <BodyText>{content.secureCloudVideoStorage.text}</BodyText>
            </CircleAndText>
            <CircleAndText>
              <Circle>
                <IconImg className="material-icons-outlined">
                  {"video_settings"}
                </IconImg>
              </Circle>
              <IconHeading>{content.smartTaggingTechnology.heading}</IconHeading>
              <BodyText>{content.smartTaggingTechnology.text}</BodyText>
            </CircleAndText>
            <CircleAndText>
              <Circle>
                <IconImg className="material-icons-outlined">
                  {"important_devices"}
                </IconImg>
              </Circle>
              <IconHeading>{content.accessibleAnytimeAnywhere.heading}</IconHeading>
              <BodyText>{content.accessibleAnytimeAnywhere.text}</BodyText>
            </CircleAndText>
          </CirclesContainer>
          <ReviewContainer>
            <ReviewHeading>{content.feedbackFromTheFrontline}</ReviewHeading>
            <Screenshot src={AndrewDickson} alt="Andrew Dickson" />
            <TestimonyText>
              <strong>{content.andrewDickson.name}</strong>
              {content.andrewDickson.text}
            </TestimonyText>
            <Screenshot src={ManchesterStorm} alt="Manchester Storm" />
            <TestimonyText>
              <strong>{content.mattGinn.name}</strong>
              {content.mattGinn.text}
            </TestimonyText>
          </ReviewContainer>
          <DemoSection>
            <DemoHeading>{content.bookDemo.heading}</DemoHeading>
            <PlatformsImg src={Platforms} alt="Platforms Image" />
            <DemoText>{content.bookDemo.text}</DemoText>
            <DemoButton href="https://calendly.com/adam-sportvantage/30min">{content.bookDemo.button}</DemoButton>
          </DemoSection>
        </ContentContainer>
      </ParentContainer>
    </>
  );
};

export default Home;
