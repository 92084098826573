import { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Button from "../common/button/Button";
import { mobileBreakpoint, spacing12, spacing24, spacing32 } from "../../theme";
import logo from "../../assets/logo/svg/black-no-bkg.svg";
import axios from "axios";

const ParentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8rem 0;
  @media only screen and (max-width: ${mobileBreakpoint}) {
    padding: 120px 0 0;
  }
`;

const SignInContainer = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: ${spacing12};
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: ${mobileBreakpoint}) {
    width: 300px;
  }
`;

const InputHeading = styled.label`
  font-size: 14px;
  color: #8c8c8b;
  margin: ${spacing24} ${spacing12} ${spacing12};
  font-weight: bold;
`;

const TextField = styled.input`
  height: 26px;
  font-family: Lato;
  padding-left: 12px;
  margin: 0 ${spacing12} ${spacing12};
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #fe4c40;
  }
`;

const CtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${spacing32};
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0.5rem 0 0 0;
  color: #8c8c8b;
  font-family: Lato;
`;

const ButtonContainer = styled.div`
  width: 80%;
`;

const SignUpCta = styled.a`
  all: unset;
  margin: 0 0 0 0.5rem;
  color: #fe4c40;
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: #fe4c40;
  font-size: 14px;
  text-align: center;
  margin-top: ${spacing12};
`;

export const SvLogo = styled.img`
  height: 50px;
`;

const SignInView = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoginError, setShowLoginError] = useState(false);
  const history = useHistory();

  const onLogin = async () => {
    try {
      const response = await axios.post('/login', { email, password });
      const { accessToken, refreshToken } = response.data;

      // Store tokens securely (e.g., in localStorage or cookies)
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      history.push("/app");
    } catch (error) {
      setShowLoginError(true);
    }
  };

  return (
    <ParentContainer>
      <SignInContainer>
        <SvLogo src={logo} alt={logo} />
        <InputHeading htmlFor="email">Email</InputHeading>
        <TextField
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <InputHeading htmlFor="password">Password</InputHeading>
          <TextField
            id="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        {showLoginError && (
          <ErrorText>Invalid login details - Try again</ErrorText>
        )}
        <CtaContainer>
          <ButtonContainer>
            <Button text="Login" onClick={onLogin} isActive={true} />
          </ButtonContainer>
        </CtaContainer>
      </SignInContainer>
      <SignUpContainer>
        Having trouble logging in?
        <SignUpCta href="mailto:adam@sportvantage.co.uk">Get in touch</SignUpCta>
      </SignUpContainer>
    </ParentContainer>
  );
};

export default SignInView;
