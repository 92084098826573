import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/app-router/app-router";
import "material-icons/iconfont/material-icons.css";
import 'react-multi-carousel/lib/styles.css'

function App() {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
}

export default App;
