import LeagueOverview from "./league-overview/league-overview";
import TeamOverview from "./team-overview/team-overview";
import CreatePlayer from "./input-forms/create-player/create-player";
import GameResultMomentView from "./gameResultMomentView/gameResultMomentView";
import CreateResult from "./input-forms/createResult/createResult";
import CreateMoment from "./input-forms/createMoment/createMoment";
import VideoUpload from "./video-upload/video-upload";
import PlayerMomentView from "./playerMomentView/playerMomentView";
import PlaylistView from "./playlists/playlistView";
import SignInView from "./sign-in/sign-in";
import Home from "./pages/home";

interface Route {
  path: string;
  component: any;
  componentProps?: [];
  authenticatedRoute?: boolean;
}

// These route paths must be in order of specificity
// e.g. /team/player must come before /team otherwise it will be unreachable
// Otherwise sort alphabetically

const routes: Array<Route> = [
  {
    path: "/",
    component: Home,
    authenticatedRoute: false,
  },
  {
    path: "/login",
    component: SignInView,
    authenticatedRoute: false,
  }
  // ,
  // {
  //   path: "/app",
  //   component: LeagueOverview,
  //   authenticatedRoute: false,
  // },
  // {
  //   path: "/team",
  //   component: TeamOverview,
  //   authenticatedRoute: false,
  // },
  // {
  //   path: "/createPlayer",
  //   component: CreatePlayer,
  //   authenticatedRoute: false,
  // },
  // {
  //   path: "/createResult",
  //   component: CreateResult,
  //   authenticatedRoute: false,
  // },
  // {
  //   path: "/createMoment",
  //   component: CreateMoment,
  //   authenticatedRoute: false,
  // },
  // {
  //   path: "/gameResult",
  //   component: GameResultMomentView,
  //   authenticatedRoute: false,
  // },
  // {
  //   path: "/athlete",
  //   component: PlayerMomentView,
  //   authenticatedRoute: false,
  // },
  // {
  //   path: "/playlist",
  //   component: PlaylistView,
  //   authenticatedRoute: false,
  // },
  // {
  //   path: "/upload",
  //   component: VideoUpload,
  //   authenticatedRoute: false,
  // },
];

export default routes;
