export const content = {
  bigText: "Elevate Performance. Transform Analysis.",
  bodyText:
    "Experience the cutting-edge platform built for the modern athlete and coach. Access game video anytime, anywhere, on any internet-connected device, with no need for technical expertise or expensive hardware. Designed exclusively for sport, Sportvantage focuses on meaningful moments and real impact instead of irrelevant statistics. Dive into season-long trends, uncover strengths and weaknesses, and make smarter decisions with video-first analysis that gives you and your team the competitive edge",
  secureCloudVideoStorage: {
    heading: "Secure Cloud Video Storage",
    text: "Your game videos are safely uploaded to our secure cloud servers, allowing easy access for all team members anytime, anywhere. No need for complicated setups—just upload and go.",
  },
  smartTaggingTechnology: {
    heading: "Smart Tagging Technology",
    text: "Our custom tagging system ensures every critical play is accurately labeled, making it effortless to find and analyse key moments in every game. Let the data guide your team’s performance.",
  },
  accessibleAnytimeAnywhere: {
    heading: "Accessible Anytime, Anywhere",
    text: "Sportvantage works seamlessly across all devices, from desktop to mobile. Easy to use, with no tech expertise required, so you can focus on improving performance, not learning software.",
  },
  feedbackFromTheFrontline: "Feedback from the Frontline",
  andrewDickson: {
    name: "Andrew Dickson - Goalkeeper Team Ireland",
    text: "Using Sportvantage during my time in Bratislava was a fun experience! I could easily access it right from my phone, and it was a breeze to navigate. The speed and insights it provided were seriously impressive. Getting all the data and relevant videos on the same day as the game, with everything loading instantly, was a game-changer. The other players in the team loved comparing stats, and it helped us pinpoint areas where we could improve, giving us areas to focus on in the tournament. I can't wait to see how Sportvantage evolves and I'm stoked to keep using it in the future",
  },
  mattGinn: {
    name: "Matt Ginn - Head Coach Manchester Storm",
    text: "Sportvantage was a real asset to our team. The introduction to the website by Adam was clear, and he explained in depth what this platform can offer, and it did not disappoint. The ease of cutting, storing, and accessing game footage, sorted by team, saved us so much time and streamlined our video analysis process. I found it intuitive and user-friendly, really useful to have on multiple devices. Which made it easy to share key moments with the team. The support from the Sportvantage team was excellent, with quick response times and relevant insights that exceeded our expectations. The overall experience was impressive. Sportvantage offers great value for money and has definitely delivered on its promise to enhance our game preparation and analysis.",
  },
  bookDemo: {
    heading: "Book a Demo",
    text: "Experience the full potential of Sportvantage. Book a demo with our team to see how our platform can transform your team's performance analysis.",
    button: "Book Now",
  },
};
